<template>
  <div class="permission">
    <b-table
      :data="allPermissions"
      ref="table"
      paginated
      per-page="10"
      :striped="true"
      :debounce-search="1000"
      aria-next-label="Next page"
      aria-previous-label="Previous page"
      aria-page-label="Page"
      aria-current-label="Current page"
    >
      <b-table-column
        field="name"
        label="Permissão"
        :searchable="true"
        sortable
        v-slot="props"
      >
        {{ props.row.name }}
      </b-table-column>
    </b-table>
  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex'

export default {
  name: 'Permissions',
  data() {
    return {}
  },
  created() {
    this.init()
  },
  computed: {
    ...mapState('permissions', ['allPermissions']),
  },
  methods: {
    ...mapActions('permissions', ['getAllPermissions']),
    async init() {
      await this.getAllPermissions()
    },
  },
}
</script>

<style scoped></style>
